import React from 'react';
import { Header } from 'iqm-framework';

import './styles.scss';

export class NavigationWrapper extends React.Component {
  handleLogout = () => {
    document.location.reload();
  };

  render() {
    return (
      <Header
        backButton={false}
        title="Audiences"
        leftLogo={'Audience Builder'}
        onLogout={this.handleLogout}
      >
        <div id="topNavButtons" className={`nav-bar__content`}/>
      </Header>
    );
  }
}
