import {tableData} from '../../components/consts';
import {Table} from '../../models/Table';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { tableConstants } from './constants';
import { User } from '../../models/User';

export interface TableState {
  data: Table;
}

const defaultTableState: TableState = {
  data: tableData,
};

const getData = (state: TableState, action: Action<Table>): TableState => {
  return {
    ...state,
    data: action.payload,
  };
};

const reducer = reducerFromMap<any>(
  defaultTableState,
  {
    [tableConstants.GET_DATA]: getData,
  },
);

export const table = (state: TableState = defaultTableState, action: Action<any>) => reducer(state, action);
