import { OptionData } from './Option';

export enum District {
  state = 'state',
  house = 'house',
}

export type LocationOption = OptionData<LocationState, number>;

export interface DistrictCodeDTO {
  district_id: string;
  district_name: string;
  geo_code: number;
  id: number;
}

export interface DistrictCode {
  districtId: string;
  districtName: string;
  geoCode: number;
  id: number;
}

export const convertDistrictCodeToDTO = (value: DistrictCode): DistrictCodeDTO => {
  return {
    district_id: value.districtId,
    district_name: value.districtName,
    geo_code: value.geoCode,
    id: value.id,
  };
};

export const convertDistrictCodeFromDTO = (value: DistrictCodeDTO): DistrictCode => {
  return {
    id: value.id,
    geoCode: value.geo_code,
    districtName: value.district_name,
    districtId: value.district_id,
  };
};

export interface LocationStateDTO {
  id: number;
  state_code: string;
  state_name: string;
}

export interface LocationState {
  id: number;
  stateCode: string;
  stateName: string;
}

export const convertLocationStateToDTO = (value: LocationState): LocationStateDTO => {
  return {
    state_code: value.stateCode,
    state_name: value.stateName,
    id: value.id,
  };
};

export const convertLocationStateFromDTO = (value: LocationStateDTO): LocationState => {
  return {
    id: value.id,
    stateName: value.state_name,
    stateCode: value.state_code,
  };
};
