import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import { auth, AuthState } from './auth/reducer';
import { app, ApplicationState } from './app/reducer';
import { map, MapState } from './map/reducer';
import { table, TableState } from './table/reducer';

export interface AppState {
  auth: AuthState;
  app: ApplicationState;
  map: MapState;
  table: TableState;
}

const reducer = combineReducers({
  auth,
  app,
  map,
  table,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore<any, any, any, any>(reducer, composeEnhancers(applyMiddleware(thunk)));
