import { User } from '../../models/User';
import { Dispatch } from 'redux';
import { createAction } from '../../utils/actions';
import { authConstants } from './constants';
import { createIqmInstance } from '../../api';

export interface Authorized {
  authorized: (user: User) => void;
}

export const authActions = {
  authorized(user: User) {
    return (dispatch: Dispatch) => {
      createIqmInstance({
        apiToken: user.apiToken,
        domainURL: user.domainURL,
      });
      dispatch(createAction<User>(authConstants.LOGIN_SUCCESS, user));
    };
  },
};
