import React from 'react';
import 'moment-timezone';
import { localStorageService } from './services/localStorage';
import { User } from './models/User';
import { store } from './store';
import { authActions } from './store/auth/actions';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { routes } from './routes';
import { Route as RouteModel } from './routes';
import { PrivateRoute } from './components/PrivateRoute';
import './App.scss';

export class App extends React.Component {
  constructor(props: any) {
    super(props);

    const info = localStorageService.getBaseInfo<User>();
    if (info) {
      store.dispatch(authActions.authorized(info));
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="app">
            <Switch>
              {routes.map((route: RouteModel, index: number) => {
                return (
                  route.private ?
                    <PrivateRoute key={index} component={route.component} path={route.path} {...this.props}/> :
                    <Route key={index} path={route.path} component={route.component} exact={route.exact}/>
                )
              })}
              <Redirect to={'/'}/>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
