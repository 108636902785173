import React from 'react';
import { connect } from 'react-redux';
import { LoginRoute } from 'iqm-framework';
import { authActions, Authorized } from '../../store/auth/actions';
import { RouteComponentProps } from 'react-router';
import { AppState } from '../../store';
import { AuthState } from '../../store/auth/reducer';
import { User } from '../../models/User';

interface Props extends RouteComponentProps, Authorized {
  auth: AuthState;
}

class LoginComponent extends React.PureComponent<Props> {
  handleAuthorized = (data: User) => {
    this.props.authorized(data);
  };

  componentDidUpdate() {
    if (this.props.auth.authorized) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <LoginRoute
        {...this.props}
        docTitle={'Campaigns | IQM Corporation'}
        onSuccess={this.handleAuthorized}
      />
    );
  }
}

const mapState = (state: AppState) => ({
  auth: state.auth,
});
const mapActions = {
  authorized: authActions.authorized,
};

export const LoginPage = connect(mapState, mapActions)(LoginComponent);
