import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { authConstants } from './constants';
import { User } from '../../models/User';

export interface AuthState {
  user: User | null;
  authorized: boolean;
}

export const defaultAuthState: AuthState = {
  user: null,
  authorized: false,
};

function loginSuccess(state: AuthState, action: Action<User>): AuthState {
  return {
    ...state,
    user: action.payload,
    authorized: true,
  };
}

const reducer = reducerFromMap<AuthState>(
  defaultAuthState,
  {
    [authConstants.LOGIN_SUCCESS]: loginSuccess,
  }
);

export const auth = (state: AuthState = defaultAuthState, action: Action<any>) => reducer(state, action);
