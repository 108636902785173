import { Logger } from '../utils/logger';
import { getInstance } from './index';
import { AudienceGroup, AudienceSegment } from '../models/Audience';
import { AxiosResponse } from 'axios';

export const Segments = async (): Promise<AudienceSegment[]> => {
  try {
    const response: AxiosResponse<AudienceSegment[]> = await getInstance()
      .get('/audience_segments_group_list');
    
    return response.data;
  } catch (e) {
    Logger.log('Error while get audience segment list', e);
    return [];
  }
};

export const Groups = async (id: number = 0): Promise<AudienceGroup[]> => {
  try {
    const response: AxiosResponse<AudienceGroup[]> = await getInstance()
      .get(`/audience_groups/${id}`);
    
    return response.data;
  } catch (e) {
    Logger.log('Error while get audience group list', e);
    return [];
  }
};
