import { Country } from '../models/Country';
import { Logger } from '../utils/logger';
import { getInstance, Params } from './index';
import { AxiosResponse } from 'axios';
import { GetResponse, GetResponseWithData } from '../models/Response';
import { Timezone } from '../models/Timezone';
import { DeviceType } from '../models/Device';
import { Dictionary } from '../models/Dictionary';
import {
  convertDistrictCodeFromDTO, convertLocationStateFromDTO,
  DistrictCode,
  DistrictCodeDTO,
  LocationState,
  LocationStateDTO
} from '../models/Location';

let countriesPromise: Promise<AxiosResponse<GetResponse<Country[]>>>;
let timezonesPromise: Promise<AxiosResponse<GetResponse<Timezone[]>>>;
let deviceTypesPromise: Promise<AxiosResponse<DeviceType[]>>;
let ageRangePromise: Promise<AxiosResponse<GetResponseWithData<Dictionary[]>>>;
let genderPromise: Promise<AxiosResponse<GetResponseWithData<Dictionary[]>>>;
let languagePromise: Promise<AxiosResponse<GetResponseWithData<Dictionary[]>>>;
let interestsPromise: Promise<AxiosResponse<Dictionary[]>>;
let incomeRangePromise: Promise<AxiosResponse<GetResponseWithData<Dictionary[]>>>;
let ethnicityPromise: Promise<AxiosResponse<GetResponseWithData<Dictionary[]>>>;
let carriersPromise: Promise<AxiosResponse<Dictionary[]>>;
let networkTypesPromise: Promise<AxiosResponse<Dictionary[]>>;
let trafficTypesPromise: Promise<AxiosResponse<Dictionary[]>>;
let manufacturersPromise: Promise<AxiosResponse<Dictionary[]>>;
let deviceOsPromise: Promise<AxiosResponse<Dictionary[]>>;
let districtCodesPromise: Promise<AxiosResponse<DistrictCodeDTO[]>>;
let senateStatesPromise: Promise<AxiosResponse<LocationStateDTO[]>>;
let houseStatesPromise: Promise<AxiosResponse<LocationStateDTO[]>>;

export const Countries = async (): Promise<Country[]> => {
  try {
    const response: AxiosResponse<GetResponse<Country[]>> = await (countriesPromise || (countriesPromise = getInstance()
      .get('/v2/adv/static/country/list')));

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while get countries', e);
    return [];
  }
};

export const Timezones = async (): Promise<Timezone[]> => {
  try {
    const response: AxiosResponse<GetResponse<Timezone[]>> = await (timezonesPromise || (timezonesPromise = getInstance()
      .get('/v2/adv/static/timezone/list')));

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while get timezones', e);
    return [];
  }
};

export const DeviceTypes = async (): Promise<DeviceType[]> => {
  try {
    const response: AxiosResponse<DeviceType[]> = await (deviceTypesPromise || (deviceTypesPromise = getInstance()
      .get('/device_types')));

    return response.data;
  }
  catch (e) {
    Logger.log('Error while get device types', e);
    return [];
  }
};

export const AgeRange = async (params: Params): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<Dictionary[]>> = await (ageRangePromise || (ageRangePromise = getInstance()
      .get('/v2/master/segment/age', { params })));

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while get age range list', e);
    return [];
  }
};

export const Gender = async (params: Params): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<Dictionary[]>> = await (genderPromise || (genderPromise = getInstance()
      .get('/v2/master/segment/gender', { params })));

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while get gender list', e);
    return [];
  }
};

export const Language = async (params: Params): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<Dictionary[]>> = await (languagePromise || (languagePromise = getInstance()
      .get('/v2/master/segment/language', { params })));

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while get language list', e);
    return [];
  }
};

export const Interests = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (interestsPromise || (interestsPromise = getInstance()
      .get('/users/interest')));

    return response.data;
  } catch (e) {
    Logger.log('Error while get interests list', e);
    return [];
  }
};

export const IncomeRange = async (params: Params): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<Dictionary[]>> = await (incomeRangePromise || (incomeRangePromise = getInstance()
      .get('/v2/master/segment/income', { params })));

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while get income range list', e);
    return [];
  }
};

export const Ethnicity = async (params: Params): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<Dictionary[]>> = await (ethnicityPromise || (ethnicityPromise = getInstance()
      .get('/v2/master/segment/ethnicity', { params })));

    return response.data.responseObject.data;
  } catch (e) {
    Logger.log('Error while get ethnicity list', e);
    return [];
  }
};

// TODO: Fix with real data. What country ID we need to use?
export const Carriers = async (countryId: string | number): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (carriersPromise || (carriersPromise = getInstance()
      .get(`/countries/${countryId}/carriers`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get carriers list', e);
    return [];
  }
};

export const NetworkTypes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (networkTypesPromise || (networkTypesPromise = getInstance()
      .get(`/network_types`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get network types list', e);
    return [];
  }
};

export const TrafficTypes = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (trafficTypesPromise || (trafficTypesPromise = getInstance()
      .get(`/source_traffic_types`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get traffic types list', e);
    return [];
  }
};

export const Manufacturers = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (manufacturersPromise || (manufacturersPromise = getInstance()
      .get(`/manufacturers`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get manufacturers list', e);
    return [];
  }
};

export const DeviceOs = async (): Promise<Dictionary[]> => {
  try {
    const response: AxiosResponse<Dictionary[]> = await (deviceOsPromise || (deviceOsPromise = getInstance()
      .get(`/device_os`)));

    return response.data;
  } catch (e) {
    Logger.log('Error while get device os list', e);
    return [];
  }
};

export const DistrictCodes = async (): Promise<DistrictCode[]> => {
  try {
    const response: AxiosResponse<DistrictCodeDTO[]> = await (districtCodesPromise || (districtCodesPromise = getInstance()
      .get(`/district_codes`)));

    return response.data.map(d => convertDistrictCodeFromDTO(d));
  } catch (e) {
    Logger.log('Error while get district codes list', e);
    return [];
  }
};

export const SenateStates = async (): Promise<LocationState[]> => {
  try {
    const response: AxiosResponse<LocationStateDTO[]> = await (senateStatesPromise || (senateStatesPromise = getInstance()
      .get(`/list/senate_states`)));

    return response.data.map(d => convertLocationStateFromDTO(d));
  } catch (e) {
    Logger.log('Error while get senate states list', e);
    return [];
  }
};

export const HouseStates = async (): Promise<LocationState[]> => {
  try {
    const response: AxiosResponse<LocationStateDTO[]> = await (houseStatesPromise || (houseStatesPromise = getInstance()
      .get(`/list/house_states`)));

    return response.data.map(d => convertLocationStateFromDTO(d));
  } catch (e) {
    Logger.log('Error while get house states list', e);
    return [];
  }
};
