import { CampaignGroup } from '../models/CampaignGroup';
import { Logger } from '../utils/logger';
import { getInstance } from './index';
import { AxiosResponse } from 'axios';
import { GetResponse } from '../models/Response';

interface Params {
  advertiserId: number;
  dspId: number;
}

interface CampaignGroupParams extends Params {
  includeChild: 0 | 1;
}

interface CreateGroupParams extends Params {
  name: string;
}

export const List = async (params: Params): Promise<CampaignGroup[]> => {
  try {
    const response: AxiosResponse<GetResponse<CampaignGroup[]>> = await getInstance()
      .get('/v2/adv/campaigngroup/list', {
        params,
      });

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while get campaign group list', e);
    return [];
  }
};

export const CampaignList = async (params: CampaignGroupParams): Promise<CampaignGroup[]> => {
  try {
    const response: AxiosResponse<GetResponse<CampaignGroup[]>> = await getInstance()
      .get('/v2/adv/campaigngroup/campaign/list', {
        params,
      });

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while get campaign group list', e);
    return [];
  }
};

export const CreateGroup = async (data: CreateGroupParams): Promise<CampaignGroup> => {
  try {
    const response: AxiosResponse<GetResponse<CampaignGroup>> = await getInstance()
      .post('/v2/adv/campaigngroup', {
        ...data,
        parentId: '0',
      });

    return response.data.responseObject;
  } catch (e) {
    Logger.log('Error while create campaign group', e);
    throw e;
  }
};
