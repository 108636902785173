import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { mapConstants } from './constants';
import { PolygonExtended } from '../../models/PolygonExtended';
import { Option } from '../../models/Option';
import { LocationOption } from '../../models/Location';
import { LatLngBoundsLiteral, LatLngLiteral, LatLngBounds } from '../../models/Google';

const USA_CENTER: LatLngLiteral = { lat: 41.021294, lng: -101.973788 };
const USA_ZOOM = 4;

export type MapBounds = null | LatLngBoundsLiteral | LatLngBounds;

export interface MapState {
  preSaved: PolygonExtended[];
  saved: PolygonExtended[];
  selectedCounty: Option | null;
  selectedState: LocationOption | null;
  selectedStateHouse: LocationOption | null;
  center: LatLngLiteral;
  mapBounds: MapBounds;
  zoom: number;
  houseStateDifference: LocationOption[];
}

export const defaultMapState: MapState = {
  preSaved: [],
  saved: [],
  selectedCounty: null,
  selectedState: null,
  selectedStateHouse: null,
  center: USA_CENTER,
  mapBounds: null,
  zoom: USA_ZOOM,
  houseStateDifference: [],
};

function pushPreSavedPolygon(state: MapState, action: Action<PolygonExtended>): MapState {
  return {
    ...state,
    preSaved: [
      ...state.preSaved,
      action.payload,
    ],
  };
}

function clearPreSavedPolygons(state: MapState): MapState {
  return {
    ...state,
    preSaved: [],
  };
}

function addPolygonsToSaved(state: MapState): MapState {
  return {
    ...state,
    saved: [
      ...state.saved,
      ...state.preSaved,
    ],
    preSaved: [],
  };
}

function removePolygonFromSaved(state: MapState, action: Action<PolygonExtended>): MapState {
  return {
    ...state,
    saved: state.saved.filter(p => p.id !== action.payload.id),
  };
}

function countyChanged(state: MapState, action: Action<Option | null>): MapState {
  return {
    ...state,
    selectedCounty: action.payload,
  };
}

function stateChanged(state: MapState, action: Action<LocationOption>): MapState {
  return {
    ...state,
    selectedState: action.payload,
  };
}

function stateHouseChanged(state: MapState, action: Action<LocationOption>): MapState {
  return {
    ...state,
    selectedStateHouse: action.payload,
  };
}

function setMapCenter(state: MapState, action: Action<LatLngLiteral>): MapState {
  return {
    ...state,
    center: action.payload,
  };
}

function setMapBounds(state: MapState, action: Action<MapBounds>): MapState {
  return {
    ...state,
    mapBounds: action.payload,
  };
}

function setMapDefaultCenter(state: MapState): MapState {
  return {
    ...state,
    center: USA_CENTER,
    zoom: USA_ZOOM,
  };
}

function setHouseStateDifference(state: MapState, action: Action<LocationOption[]>): MapState {
  return {
    ...state,
    houseStateDifference: action.payload,
  };
}

const reducer = reducerFromMap<MapState>(
  defaultMapState,
  {
    [mapConstants.PUSH__PRESAVED_POLYGON]: pushPreSavedPolygon,
    [mapConstants.CLEAR__PRESAVED_POLYGONS]: clearPreSavedPolygons,
    [mapConstants.ADD__POLYGONS_TO_SAVED]: addPolygonsToSaved,
    [mapConstants.REMOVE__POLYGON_FOM_SAVED]: removePolygonFromSaved,
    [mapConstants.CHANGED__LOCATION_COUNTY]: countyChanged,
    [mapConstants.CHANGED__LOCATION_STATE_SENATE]: stateChanged,
    [mapConstants.CHANGED__LOCATION_STATE_HOUSE]: stateHouseChanged,
    [mapConstants.SET__MAP_CENTER]: setMapCenter,
    [mapConstants.SET__MAP_BOUNDS]: setMapBounds,
    [mapConstants.SET__MAP_DEFAULT_CENTER]: setMapDefaultCenter,
    [mapConstants.SET__HOUSE_STATE_DIFFERENCE]: setHouseStateDifference,
  },
);

export const map = (state: MapState = defaultMapState, action: Action<any>) => reducer(state, action);
