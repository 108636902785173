export const mapConstants = {
  PUSH__PRESAVED_POLYGON: 'PUSH__PRESAVED_POLYGON',
  CLEAR__PRESAVED_POLYGONS: 'CLEAR__PRESAVED_POLYGONS',
  ADD__POLYGONS_TO_SAVED: 'ADD__POLYGONS_TO_SAVED',
  REMOVE__POLYGON_FOM_SAVED: 'REMOVE__POLYGON_FOM_SAVED',
  CHANGED__LOCATION_COUNTY: 'CHANGED__LOCATION_COUNTY',
  CHANGED__LOCATION_STATE_SENATE: 'CHANGED__LOCATION_STATE_SENATE',
  CHANGED__LOCATION_STATE_HOUSE: 'CHANGED__LOCATION_STATE_HOUSE',
  SET__MAP_CENTER: 'SET__MAP_CENTER',
  SET__MAP_BOUNDS: 'SET__MAP_BOUNDS',
  SET__MAP_DEFAULT_CENTER: 'SET__MAP_DEFAULT_CENTER',
  SET__HOUSE_STATE_DIFFERENCE: 'SET__HOUSE_STATE_DIFFERENCE',
};
