import { LoginPage } from '../pages/LoginPage';
import { MainPage } from '../pages/MainPage';

export type Route = {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  private?: boolean;
}

export const routes: Route[] = [
  {
    path: '/login/:token?/:base_url?',
    component: LoginPage,
  },
  {
    path: '/',
    exact: true,
    component: MainPage,
    private: true,
  },
];
