import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { applicationConstants } from './constants';

export interface ApplicationState {
  sidebarOpened: boolean;
}

const defaultApplicationState: ApplicationState = {
  sidebarOpened: true,
};

function toggleSidebar(state: ApplicationState): ApplicationState {
  return {
    sidebarOpened: !state.sidebarOpened,
  };
}

const reducer = reducerFromMap<ApplicationState>(
  defaultApplicationState,
  {
    [applicationConstants.TOGGLE__SIDEBAR_MENU]: toggleSidebar,
  }
);

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) => reducer(state, action);
