import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../store';

interface Props extends RouteComponentProps {
  component: React.ComponentType,
  path: string,
}

interface InnerProps extends Props {
  isAuthenticated: boolean,
}

class PrivateRouteComponent extends React.Component<InnerProps> {
  render() {
    const {
      component: Component,
      isAuthenticated,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={(props: any) => {
          return isAuthenticated ?
            <Component {...props}/> :
            <Redirect to={'/login'}/>
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: state.auth.authorized,
  };
};

export const PrivateRoute: React.ComponentType<any> = connect(mapStateToProps)(PrivateRouteComponent);
